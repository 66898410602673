<template lang="pug">
.container
  .wallet-form-box.card
    .card-body
      .row
        .col-md-10
          h4 Upabhokta Samiti Water
          transition(name="fade", mode="out-in")
            .form-group(v-if="message")
              .show-message {{ message }}
          transition(name="fade", mode="out-in")
            .ml-3(v-if="messageDetails")
              .row.mt-3
                .col-md-6.mt-3(
                  v-for="(message, index) in messageDetails",
                  :key="index"
                )
                  label.text-capitalize {{ index }}
                  input.form-control.form-control-sm(
                    :value="message",
                    disabled
                  )
              .form-group.mt-5
                show-bank(
                  :bankClicked="bankClicked",
                  :banks="banks",
                  v-if="!loading"
                )
            form(@submit.prevent="getDetails", v-else)
              .form-group
                label Office
                  i.fa.fa-asterisk
                select.form-control(
                  v-model="upabhoktaDetails.OfficeCode",
                  :disabled="loading",
                  required
                )
                  option(:value="null", disabled) Select Office
                  option(
                    v-for="counter in allCounter",
                    :key="counter.OfficeCodes",
                    :value="counter.OfficeCodes"
                  ) {{ counter.Office }}
              .form-group
                label Customer ID
                  i.fa.fa-asterisk
                input.form-control(
                  type="text",
                  v-model="upabhoktaDetails.CustomerId",
                  required
                )
              .form-group
                button.btn.btn-danger(:disabled="loading") Get Details
        .col-sm-5
          pin-confirm(
            v-if="paymentModal",
            :previewDetails="previewDetails",
            :pinVerification="verifyPin"
          )
  loading-spinner(:loading="loading")
</template>

<script>
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import api from "@/helpers/api";
import axios from "@/axios";
import payment from "@/mixins/payment";

export default {
  name: "utility-upabhokta",
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Customer ID": this.upabhoktaDetails.customerId,
        "Total Amount to Pay": "Rs. " + this.upabhoktaDetails.Amount || 0,
      };
    },
  },
  mixins: [payment],
  data: function () {
    return {
      message: "",
      allCounter: "",
      upabhoktaDetails: {
        CustomerId: "",
        OfficeCode: null,
      },
      preview: false,
      messageDetails: "",
      paymentModal: false,
      banks: "",
      loading: true,
      BASE_URL: process.env.BASE_URL,
    };
  },
  methods: {
    verifyPin(verified) {
      this.pinVerification(this.makePayment, verified);
    },
    bankClicked(bank) {
      this.upabhoktaDetails.Amount = this.messageDetails["TotalDueAmount"];
      if (this.upabhoktaDetails.Amount !== "0.00") {
        this.upabhoktaDetails.bank = bank;
        this.paymentModal = true;
      } else {
        this.message = "You dont have any due amount..";
      }
    },
    makePayment() {
      this.loading = true;
      axios
        .post("services/ubws/bill" + this.upabhoktaDetails)
        .then((res) => {
          if (res.data) {
            if (res.data.status === 1) {
              this.message = res.data.data.message;
              this.messageDetails = "";
              this.preview = false;
              this.$store.dispatch("UPDATE_BALANCE");
            } else {
              location.replace(res.data.data.apiurl);
            }
            this.loading = false;
          }
        })
        .catch((_) => {
          this.message = "Something went wrong";
          this.loading = false;
        });
    },

    getDetails() {
      this.loading = true;
      axios
        .post("api/v2/services/ubws/bill", this.upabhoktaDetails)
        .then((r) => {
          console.log(r);
          if (r.data && r.data.response && r.data.response.Code === 1) {
            if (r.data.response) {
              this.messageDetails = r.data.response;
            }
          } else {
            this.message = "Unable to fetch bill detail for provided information";
          }
          this.loading = false;
        })
        .catch((_) => {
          this.message = "Something went wrong";
          this.loading = false;
        });
    },

    /**
     * Beautify Nea Details
     * @param{array} rawArray array contains information in key value pair in form of array.
     *
     * This function converts array value pair in object key value pair.
     * Formatting <br>'s into html table structure.
     */
    beautifyUpabhoktaDetails(rawArray) {
      return rawArray;
    },
  },
  async mounted() {
    this.upabhoktaDetails.servicesId = await api.serviceIdBySlug(
      "UPABHOKTA_SAMITI_WATER"
    );
    let offices = await api.upabhoktaOfficeCodes(this.upabhoktaDetails.servicesId);
    if (offices) {
      this.allCounter = offices;
      this.banks = await api.banks();
    } else {
      this.message = "Check your internet Connection.";
    }
    this.loading = false;
  },
};
</script>
